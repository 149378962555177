@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  @font-face {
    font-family: "RoobertLight";
    src: url("./fonts/Roobert-Light.otf");
    font-display: block;
    font-weight: 300;
  }
  @font-face {
    font-family: "RoobertRegular";
    src: url("./fonts/Roobert-Regular.otf");
    font-display: block;
    font-weight: 400;
  }
  @font-face {
    font-family: "RoobertMedium";
    src: url("./fonts/Roobert-Medium.otf");
    font-display: block;
    font-weight: 500;
  }
  @font-face {
    font-family: "RoobertSemiBold";
    src: url("./fonts/Roobert-SemiBold.otf");
    font-display: block;
    font-weight: 600;
  }
  @font-face {
    font-family: "RoobertBold";
    src: url("./fonts/Roobert-Bold.otf");
    font-display: block;
    font-weight: 700;
  }
  @font-face {
    font-family: "RoobertBold";
    src: url("./fonts/Roobert-Bold.otf");
    font-display: block;
    font-weight: 800;
  }
  @font-face {
    font-family: "RoobertBold";
    src: url("./fonts/Roobert-Bold.otf");
    font-display: block;
    font-weight: 900;
  }

  html {
    font-family: "RoobertRegular", system-ui, sans-serif;
    height: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
  }

  #root {
    height: 100%;
    --toastify-color-success: #4f772d;
    --toastify-color-warning: #e81313;
  }

  * {
    scrollbar-width: auto;
    scrollbar-color: #dde0e4 #ffffff;
  }

  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #dde0e4;
    border-radius: 10px;
    border: 2px solid #ffffff;
  }
  body {
    height: 100%;
  }
  /* @media only screen and (max-width: 600px) {
    body {
      height: auto;
    }
  } */
  .body-height {
    height: calc(100% - 80px);
  }
  .social-height {
    height: calc(100% - 20px);
  }
  @media only screen and (max-width: 600px) {
    table thead {
      display: none;
    }
    .body-height {
      height: auto;
    }
    .social-height {
      height: auto;
    }
  }
}

/* dialog::backdrop {
  background-color: black;
  opacity: 0.4;
} */
dialog {
  overflow: visible;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
input:disabled {
  cursor: not-allowed;
}
.dialogInput input:-webkit-autofill,
.dialogInput input:-webkit-autofill:hover,
.dialogInput input:-webkit-autofill:focus,
.dialogInput input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #1f2124;
}

.custom-emoji-picker .epr-emoji-category-label {
  font-family: "RoobertRegular", system-ui, sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.custom-emoji-picker .epr-header,
.custom-emoji-picker .epr-emoji-list {
  background: #1f2124;
}
.custom-emoji-picker .epr-search-container input {
  font-family: "RoobertRegular", system-ui, sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.overflow-unset {
  overflow: unset;
}
